import {SourceTypes} from '@matchsource/models/source';

export class GetSourceEthnicityRaces {
  static readonly type = '[Source Ethnicity Races] Get Source Ethnicity Races';

  constructor(
    public readonly sourceId: MsApp.Guid,
    public readonly sourceType: SourceTypes = SourceTypes.Donor,
    public readonly silent = true
  ) {}
}
