import {EthnicityRacesModel, SourceTypes} from '@matchsource/models/source';
import {FormattedRaces, GetRaces, RacesState} from '@matchsource/store/races';
import {Action, createSelector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {EthnicityState, GetEthnicity} from '@matchsource/store/ethnicity';
import {DEFAULT_RACE, Nomenclature} from '@matchsource/models/nomenclature';
import {DonorApiService} from '@matchsource/api/donor';
import {CordApiService} from '@matchsource/api/cord';
import {GetSourceEthnicityRaces} from './source-ethnicity-races.actions';
import {patch} from '@ngxs/store/operators';
import {forkJoin, Observable} from 'rxjs';
import {tap} from 'rxjs/operators';

export interface SourcesEthnicityRacesStateModel {
  loading: boolean;
  loaded: boolean;
  sourcesEthnicityRaces: EthnicityRacesModel;
  error: any;
}

export interface AllSourcesEthnicityRacesStateModel {
  [sourceId: string]: SourcesEthnicityRacesStateModel;
}

export interface ExtendedEthnicityRacesModel {
  ethnicity: string;
  races: FormattedRaces;
}

const defaultSourcesEthnicityRacesState = (overrides = {}): SourcesEthnicityRacesStateModel => ({
  loading: false,
  loaded: false,
  sourcesEthnicityRaces: null,
  error: undefined,
  ...overrides,
});

@State<AllSourcesEthnicityRacesStateModel>({
  name: 'sourcesEthnicityRaces',
  defaults: {},
})
@Injectable()
export class SourceEthnicityRacesState {
  static item(sourceId: MsApp.Guid) {
    return createSelector([SourceEthnicityRacesState], (state: AllSourcesEthnicityRacesStateModel) => {
      return sourceId in state ? state[sourceId] : null;
    });
  }

  static source(sourceId: MsApp.Guid) {
    return createSelector([this.item(sourceId)], (item: SourcesEthnicityRacesStateModel) => {
      return item?.sourcesEthnicityRaces ?? null;
    });
  }

  static sourceIsLoaded(sourceId: MsApp.Guid) {
    return createSelector([this.item(sourceId)], (item: SourcesEthnicityRacesStateModel) => {
      return item?.loaded ?? false;
    });
  }

  static sourceRaces(sourceId: MsApp.Guid) {
    return createSelector([this.source(sourceId)], (source: EthnicityRacesModel) => {
      return source?.raceCodes ?? null;
    });
  }

  static sourceExtendedEthnicityRaces(sourceId: MsApp.Guid) {
    return createSelector(
      [this.source(sourceId), RacesState.formatRaces, EthnicityState.map],
      (
        source: EthnicityRacesModel,
        formatRaces: (races: string[], useDefault: boolean) => FormattedRaces,
        ethnicities: MsApp.Dictionary<Nomenclature>
      ) => {
        if (!source) {
          return null;
        }

        const raceCodes =
          !source.raceCodes || source.raceCodes.length === 0 ? [source.primaryRaceCode] : source.raceCodes;
        const formattedRaces = formatRaces(raceCodes, true);

        return {
          ethnicity: ethnicities[source.ethnicityCode]?.description ?? DEFAULT_RACE.ethnicityDescription,
          races: formattedRaces,
        };
      }
    );
  }

  constructor(
    private readonly donorApi: DonorApiService,
    private readonly cordApi: CordApiService,
    private readonly store: Store
  ) {}

  @Action(GetSourceEthnicityRaces)
  getSourceEthnicityRaces(
    ctx: StateContext<AllSourcesEthnicityRacesStateModel>,
    {sourceId, sourceType, silent}: GetSourceEthnicityRaces
  ) {
    const state = ctx.getState();
    const tcState = sourceId in state ? state[sourceId] : null;

    if (tcState && (tcState.loaded || tcState.loading)) {
      return;
    }

    ctx.setState(
      patch({
        [sourceId]: defaultSourcesEthnicityRacesState({loading: true}),
      })
    );

    return forkJoin([
      this.getSourceEthnicityRace(sourceId, sourceType, silent).pipe(
        tap(sourcesEthnicityRaces => {
          ctx.setState(
            patch({
              [sourceId]: patch<SourcesEthnicityRacesStateModel>({
                loading: false,
                loaded: true,
                sourcesEthnicityRaces,
              }),
            })
          );
        })
      ),
      this.store.dispatch([new GetRaces(true), new GetEthnicity(true)]),
    ]);
  }

  private getSourceEthnicityRace(
    sourceId: MsApp.Guid,
    sourceType: SourceTypes,
    silent: boolean
  ): Observable<EthnicityRacesModel> {
    switch (sourceType) {
      case SourceTypes.Donor:
        return this.donorApi.getDonorEthnicityRace(sourceId, silent);

      case SourceTypes.Cord:
        return this.cordApi.getCordEthnicityRace(sourceId, silent);

      default:
        throw new Error(`Unknown "{$sourceType}" source type.`);
    }
  }
}
